import { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { SWRConfig } from 'swr'
import { unstable_serialize } from 'swr/infinite'
import ChangelogPage from '../../components/ChangelogPage'
import SeoMetaPublic from '../../components/SeoMetaPublic'
import { changelogGetKey } from '../../data/organization'
import { getClientIp } from '../../lib/getClientIp'
import { getHTTPProtocol, redirectToRefresh } from '../../lib/subdomain'
import { defaultSWRConfig, determineSSRTheme, getLocaleFromSSR } from '@/lib/utils'
import { axiosGetRequest, identifySSOUser } from '..'
import { IOrganization } from '@/interfaces/IOrganization'
import { IChangelogFilters } from '@/interfaces/IChangelog'
import { availableLocales } from '@/lib/localizationUtils'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
  const cookie = context.req.headers.cookie
  const ip = getClientIp(context)
  const http = getHTTPProtocol()
  let jwt = context.query?.jwt

  if (context?.query?.fbclid) {
    jwt = ''
  }

  const locale = await getLocaleFromSSR(context)

  const initialFilters: IChangelogFilters = {
    sortBy: typeof context.query.sortBy === 'string' ? context.query.sortBy : 'date:desc',
    state: 'live',
    changelogCategories: Array.isArray(context.query.c)
      ? context.query.c
      : typeof context.query.c === 'string'
      ? [context.query.c]
      : [],
    limit: 10,
    locale: locale,
  }

  // Construct the query string
  // Construct the query string
  const queryParams = new URLSearchParams()

  if (typeof initialFilters.sortBy === 'string') {
    queryParams.append('sortBy', initialFilters.sortBy)
  }

  if (typeof initialFilters.state === 'string') {
    queryParams.append('state', initialFilters.state)
  }

  if (typeof initialFilters.limit === 'number') {
    queryParams.append('limit', initialFilters.limit.toString())
  }

  if (typeof initialFilters.locale === 'string') {
    queryParams.append('locale', initialFilters.locale)
  }

  initialFilters?.changelogCategories?.forEach((category, index) => {
    if (typeof category === 'string') {
      queryParams.append(`changelogCategories[${index}]`, category)
    }
  })

  const [postsRes, org, user, isSubscribed, identifiedUser] = await Promise.allSettled([
    axiosGetRequest(`${http}${host}/api/v1/changelog?${queryParams.toString()}`, cookie, ip, jwt),
    axiosGetRequest(`${http}${host}/api/v1/organization`, cookie, ip, jwt),
    axiosGetRequest(`${http}${host}/api/v1/user`, cookie, ip, jwt),
    axiosGetRequest(`${http}${host}/api/v1/changelog/isSubscribed`, cookie, ip, jwt),
    identifySSOUser(http, host, cookie, ip, jwt),
  ])

  const fallback = {}
  if (postsRes.status === 'fulfilled') {
    Object.assign(fallback, {
      [unstable_serialize((pageIndex: number, previousPageData: any) =>
        changelogGetKey(pageIndex, previousPageData, initialFilters)
      )]: [postsRes.value.data],
    })
  }

  if (org.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/organization': org.value.data })
  } else {
    return redirectToRefresh(context.req, '/changelog')
  }
  if (user.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/user': user.value.data })
  }

  if (identifiedUser?.status === 'fulfilled') {
    if (identifiedUser?.value?.data?.user) {
      Object.assign(fallback, { '/v1/user': identifiedUser.value.data.user })
    }
  }
  if (isSubscribed.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/changelog/isSubscribed': isSubscribed.value.data })
  }

  if (org.status === 'fulfilled') {
    let translate = {}
    if (context.locale) {
      translate = await serverSideTranslations(context.locale)
    }
    return {
      props: {
        fallback,
        defaultTheme: determineSSRTheme(org?.value?.data, context),
        queryParams: context.query,
        org: org?.value?.data,
        initialFilters,
        ...translate,
      },
    }
  }
  return { redirect: { destination: '/login' }, props: {} }
}

const Index: React.FC<{ fallback: any; org: IOrganization; initialFilters: IChangelogFilters }> = ({
  fallback,
  org,
  initialFilters,
}) => {
  const { t } = useTranslation()

  const pageTitle =
    org?.structure?.changelog?.mainChangelogButtonText &&
    org?.structure?.changelog?.mainChangelogButtonText !== 'Changelog'
      ? org?.structure?.changelog?.mainChangelogButtonText
      : t('changelog')

  return (
    <SWRConfig value={{ fallback, ...defaultSWRConfig }}>
      <style>{`
       :root {
        --background: #fff;
      }
      `}</style>
      <div className="pb-10 sm:pb-16">
        <SeoMetaPublic
          page={pageTitle}
          ogImageProps={
            (org &&
              !org.settings.private && {
                company: {
                  name: org?.displayName,
                  logo: org?.picture,
                  themeColor: org?.color,
                  themeLinePosition: 'bottom',
                },
                title: pageTitle,
                description:
                  t('follow-new-updates-and-improvements-to-org-displayname', {
                    displayName: org.displayName,
                  }) + '.',
                type: 'root',
              }) ||
            undefined
          }
        />
        <ChangelogPage initialFilters={initialFilters} />
      </div>
    </SWRConfig>
  )
}

export default Index
